import React from 'react';

const IconHex = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
  <title>Logo</title>
  <g transform="translate(-8.000000, -2.000000)">
    <g transform="translate(11.000000, 5.000000)">
      <polygon
        id="Shape"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="39 0 0 22 0 67 39 90 78 68 78 23"
      />
    </g>
  </g>
  <text x="24" y="65" fill="currentColor" fontSize="50px" fontFamily="Consolas, serif">
    M
  </text>
</svg>
);

export default IconHex;
