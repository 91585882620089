import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  // ul.skills-list {
  //   display: grid;
  //   grid-template-columns: repeat(2, minmax(140px, 200px));
  //   grid-gap: 0 10px;
  //   padding: 0;
  //   margin: 20px 0 0 0;
  //   overflow: hidden;
  //   list-style: none;

  ul.clubs-list {
    display: grid;
    grid-template-columns: minmax(140px, 400px); /* One column */
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        // mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      // mix-blend-mode: multiply;
      // filter: grayscale(100%) contrast(1);
      // transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  // const skills = ['JavaScript', 'React', 'React Native', 'Git', 'Figma'];

  const clubs = [
    'Project Hope - Volunteer dedicated to advancing educational equity by outreaching students at underserved high schools',
    'Learning Center -  Photo Consultant fostering artistic creations among students through events and resources',
    'LA Blueprint - Full-stack developer working with non-profits for social good',
    'Creative Labs - Project Manager supporting a community of creatives to build student-led projects',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Megan and I'm full-stack engineer, specializing in creating
              intuitive and engaging user experiences. I'm passionate about leveraging modern
              technologies and design principles to deliver visually appealing and user-friendly
              applications.
            </p>
            <p>
              I'm currently attending UCLA studying cognitive science and computing. I'm involved in
              several organizations such as:
            </p>
            <ul className="clubs-list">
              {clubs && clubs.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
            <br></br>
            <p>
              In my free time, I enjoy jogging, hammocking, exploring Los Angeles via bus or
              scooters, and thrifting! I adore eating oranges, raspberries, and cherries.
            </p>

            {/* <p>
              I also recently{' '}
              <a href="https://www.newline.co/courses/build-a-spotify-connected-app">
                launched a course
              </a>{' '}
              that covers everything you need to build a web app with the Spotify API using Node
              &amp; React.
            </p> */}

            {/* <p>Here are a few technologies I’ve been working with recently:</p> */}
          </div>

          {/* <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul> */}
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/megan.jpg"
              //src = "/Users/megantieu/Downloads/WebsiteByMeganTieucopy/src/images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
